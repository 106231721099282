var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.taskAttribute.BasketID
        ? [
            _c("EmbedCe", {
              attrs: { url: _vm.ceUrl },
              on: {
                load: function($event) {
                  _vm.loaded = true
                }
              }
            }),
            _vm.loaded
              ? _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "mt-4",
                            attrs: { disabled: _vm.working, variant: "danger" },
                            on: { click: _vm.disconnect }
                          },
                          [_vm._v("Disconnect basket")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ]
        : _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-card",
                    {
                      attrs: {
                        header:
                          "Choose or create a basket to connect to this project"
                      }
                    },
                    [
                      _c("api-lookup", {
                        attrs: {
                          disabled: _vm.working,
                          searchOnMount: true,
                          multiple: false,
                          "use-post": false,
                          source: "baskets",
                          label: "Reference",
                          keyname: "BasketID"
                        },
                        model: {
                          value: _vm.basketId,
                          callback: function($$v) {
                            _vm.basketId = $$v
                          },
                          expression: "basketId"
                        }
                      }),
                      _c(
                        "b-button",
                        {
                          staticClass: "mt-4",
                          attrs: { disabled: !_vm.basketId.key || _vm.working },
                          on: { click: _vm.save }
                        },
                        [_vm._v("Link basket")]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "mt-4 ml-4",
                          attrs: { disabled: _vm.working },
                          on: { click: _vm.create }
                        },
                        [_vm._v("Create new basket")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }