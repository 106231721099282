<template lang="pug">

div
  template(v-if='taskAttribute.BasketID')
    EmbedCe(:url='ceUrl', @load='loaded = true')
    b-row(v-if='loaded')
      b-col 
        b-button.mt-4(
          :disabled="working"
          @click="disconnect" 
          variant="danger"
        ) Disconnect basket

  b-row(v-else)
    b-col(cols=12)
      b-card(header='Choose or create a basket to connect to this project')
        api-lookup(
          :disabled="working"
          v-model="basketId" 
          :searchOnMount="true"
          :multiple="false"
          :use-post="false"
          source="baskets"
          label="Reference"
          keyname="BasketID"
        )

        b-button.mt-4(
          :disabled="!basketId.key || working"
          @click="save"
        ) Link basket

        b-button.mt-4.ml-4(
          @click="create"
          :disabled="working"
        ) Create new basket
 
</template>
  
<script>

  export default {
    data () {
      return {
        working: false,
        basketId: [],
        loaded: false,
      }
    },
    components: {
      EmbedCe: () => import('@/components/EmbedCe.vue'),
      ApiLookup: () => import('@/components/ApiLookup.vue'),
    },
    inject: [
      'reloadTask'
    ],
    props: {
      taskAttribute: Object,
    },
    watch: {
    },
    methods: {
      async disconnect () {
        this.working = true
        await this.$http.put(`task-attributes/${this.taskAttribute.TaskAttributeID}/attributes/BasketID`,
          { value: '' }
        )
        this.reloadTask()
      },
      async save () {
        this.working = true
        await this.$http.put(`task-attributes/${this.taskAttribute.TaskAttributeID}/attributes/BasketID`,
          { value: this.basketId.key }
        )
        this.reloadTask()
      },
      async create () {
        this.working = true
        await this.$http.put(`task-attributes/${this.taskAttribute.TaskAttributeID}/create-basket`)
        this.reloadTask()
      }
    },
    computed: {
      ceUrl () {
        return `Basket/?BasketID=${this.taskAttribute.BasketID}`
      }
    },
    mounted () {

    }
  }

</script>